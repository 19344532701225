import { type UserFilterInput } from 'lib/graphql/__generated__/graphql';
import apolloClient from 'lib/graphql/apolloClient';
import { GET_ALL_ORGANIZATION, GET_ALL_USER, GET_CURRENT_USER } from 'lib/graphql/queries/user.query';

export const getCurrentUser = async () => {
  const response = await apolloClient.query({
    query: GET_CURRENT_USER,
    fetchPolicy: 'no-cache',
    errorPolicy: 'all' //this allows users payload without organisation without breaking
  });
  return response;
};

export const getAllUsers = async (variables: { limit: number, offset: number, filter: UserFilterInput }) => {
  const response = await apolloClient.query({
    query: GET_ALL_USER,
    fetchPolicy: 'no-cache',
    variables
  });
  return response;
};

export const getAllOrganizations = async () => {
  const response = await apolloClient.query({
    query: GET_ALL_ORGANIZATION,
    fetchPolicy: 'no-cache'
  });
  return response;
};
