import type { FunctionComponent } from 'react';
import React, { useEffect, useState } from 'react';

type AvatarProps = {
  readonly image?: string;
  readonly Icon?: FunctionComponent<React.SVGProps<SVGSVGElement>>;
  readonly alt?: string;
  readonly text?: string;
  readonly size?: number;
  readonly objRef?: React.RefObject<HTMLImageElement>;
  readonly style?: React.CSSProperties;
  readonly classNames?: string;
  readonly tooltip?: string;
  readonly backgroundColor?: string;
  readonly onClick?: () => void;
};

export default function Avatar({
  Icon,
  image,
  classNames,
  alt,
  text,
  size = 20,
  style,
  objRef,
  tooltip,
  backgroundColor,
  onClick
}: AvatarProps) {
  const [letter, setLetter] = useState<string>('');

  useEffect(() => {
    if (text) {
      setLetter(text);
    } else if (alt != null) {
      const wordArray = alt.split(' ');
      const firstWord = wordArray[0] || ' ';
      const secondWord = wordArray[1] || ' ';
      setLetter(`${firstWord[0]}${secondWord[0]}`.trim());
    }
  }, [alt]);

  if (Icon) {
    return (
      <Icon
        width={size}
        height={size}
        data-testid="member-avatar-icon"
        data-tooltip-id="app-tooltip"
        data-tooltip-content={tooltip}
        onClick={() => onClick?.()}
      />
    );
  }

  if (image) {
    return (
      <img
        data-testid="member-avatar"
        src={image}
        alt={alt ?? 'avatar'}
        className={`member_avatar ${classNames}`}
        style={{ width: size, height: size, flex: `0 0 ${size}px`, ...style }}
        referrerPolicy="no-referrer"
        ref={objRef}
        data-tooltip-id="app-tooltip"
        data-tooltip-content={tooltip}
        onClick={() => onClick?.()}
      />
    );
  }

  return (
    <div
      className="member_avatar-letter"
      data-testid="member_avatar-letter"
      style={{
        width: size,
        height: size,
        flex: `0 0 ${size}px`,
        background: backgroundColor,
        ...style
      }}
      data-tooltip-id="app-tooltip"
      data-tooltip-content={tooltip}
      onClick={() => onClick?.()}>
      {letter || 'NA'}
    </div>
  );
}
