import React from 'react';

import { ApolloProvider } from '@apollo/client';
import { store } from 'common/store';
import { ErrorFallback } from 'components/Error/ErrorFallback';
import PageLoader from 'features/ui/PageLoader/PageLoader';
import Auth0ProviderWithHistory from 'lib/auth/Auth0ProviderWithHistory';
import apolloClient from 'lib/graphql/apolloClient';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Tooltip } from 'react-tooltip';

type AppProviderProps = {
  readonly children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense fallback={<PageLoader />}>
      <ErrorBoundary FallbackComponent={ErrorFallback}>
        <ApolloProvider client={apolloClient}>
          <Provider store={store}>
            <Router>
              <Auth0ProviderWithHistory>{children}</Auth0ProviderWithHistory>
              <ToastContainer limit={3} />
              <Tooltip id="app-tooltip" className="tooltip" variant="light" />
            </Router>
          </Provider>
        </ApolloProvider>
      </ErrorBoundary>
    </React.Suspense>
  );
};
