/* eslint-disable no-console */
/* eslint-disable react/jsx-no-useless-fragment */
import { lazy } from 'react';
// import { lazy, useEffect } from 'react';

// import useSystemAuth from 'common/hooks/useSystemAuth';
// import { urlPatternValidation } from 'common/utils/urlValidation';
// import { REDIRECT_URI_KEY } from 'lib/auth/ProtectedRoute';
import type { RouteObject } from 'react-router-dom';
import { useRoutes } from 'react-router-dom';

import { protectedReviewerRoutes, protectedUserRoutes } from './protected';
import { publicRoutes } from './public';

const NotFoundPage = lazy(async () => import('pages/NotFound'));

export const AppRoutes = () => {
  const commonRoutes: RouteObject[] = [{ path: '*', element: <NotFoundPage /> }];

  const element = useRoutes([
    ...publicRoutes,
    ...protectedUserRoutes,
    ...protectedReviewerRoutes,
    ...commonRoutes
  ]);

  return <>{element}</>;
};
