import { graphql } from '../__generated__/gql';

export const GET_TEMPLATES_BY_ORGANIZATION_ID = graphql(`
  query listTemplates($organizationId: ID!) {
    listTemplates(input: { organizationId: $organizationId }) {
      id
      editable
      name
      organizationId
      template
    }
  }
`);

export const GET_LIST_DOC_TYPES = graphql(`
  query listDocTypes {
    listDocTypes {
      data {
        color
        name
      }
    }
  }
`);
