import { createSlice } from '@reduxjs/toolkit';

import type { IUser } from '../user/userSlice';

const name = 'article';

export interface IArticle {
  readonly id: string;
  readonly title: string;
  readonly status: string;
  readonly updated_at: string;
  readonly created_at: string;
  readonly creator_id: string | null;
  readonly assignee_id: string | null;
  readonly last_user_id: string | null;
  readonly last_accessed_at: string | undefined;
  readonly user?: IUser;
}

export type PageMeta = {
  readonly n_articles: number;
  readonly n_pages: number;
  readonly page: number;
  readonly count: number;
};

export type PageQuery = {
  readonly limit: number;
  readonly page: number;
};

export interface IPageControl {
  readonly next: { readonly id: string; readonly project_id: string };
  readonly prev: { readonly id: string; readonly project_id: string };
}

interface IInitialState {
  readonly articles: {
    readonly loading: boolean;
    readonly data: readonly IArticle[];
    readonly meta: PageMeta | undefined;
    readonly pageQuery: PageQuery;
  };
  readonly page_control: IPageControl | null;
}

function createInitialState() {
  return {
    articles: {
      loading: true,
      data: [],
      meta: undefined,
      pageQuery: {
        limit: 50,
        page: 0
      }
    },
    page_control: null
  };
}

function createExtraActions() {
  return {};
}

export const articleInitialState: IInitialState = createInitialState();
const extraActions = createExtraActions();

const createExtraReducers = () => {
  return;
};

const slice = createSlice({
  name,
  initialState: articleInitialState,
  extraReducers: createExtraReducers,
  reducers: {
    updateArticle() {
      return;
    }
  }
});

// exports

export const articleActions = { ...slice.actions, ...extraActions };
export const articleReducer = slice.reducer;
export const { updateArticle } = slice.actions;
