/* eslint-disable @typescript-eslint/no-explicit-any */
import type { Action, ThunkAction } from '@reduxjs/toolkit';
import { configureStore } from '@reduxjs/toolkit';
import debounce from 'lodash.debounce';
import { batchedSubscribe } from 'redux-batched-subscribe';
import logger from 'redux-logger';

import { articleReducer } from './article/articleSlice';
import { documentReducer } from './document/documentSlice';
import { jobReducer } from './job/jobSlice';
import { summaryReducer } from './summary/summarySlice';
import { templateReducer } from './template/templateSlice';
import { userReducer } from './user/userSlice';

const reducer = {
  article: articleReducer,
  user: userReducer,
  job: jobReducer,
  document: documentReducer,
  summary: summaryReducer,
  template: templateReducer
};

const debounceNotify = debounce((notify: () => void) => notify());

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware();
    if (process.env.NODE_ENV !== 'production') {
      return middleware.concat<any>(logger);
    }
    return middleware;
  },
  devTools: process.env.NODE_ENV !== 'production',
  enhancers: [batchedSubscribe(debounceNotify)]
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
