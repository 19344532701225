import React, { useEffect, useState } from 'react';

import { useAuth0 } from '@auth0/auth0-react';
import useOutsideClick from 'common/hooks/useOutsideClick';
import type { RootState } from 'common/store';
import { useAppDispatch } from 'common/store/hooks';
import { userActions } from 'common/store/user/userSlice';
import { useSelector } from 'react-redux';

import PageHeaderContainer from './PageHeaderContainer';
import UserInfo from '../../users/UserInfo';
import type { icons } from '../Icon/Icon';
import Icon from '../Icon/Icon';
import VerticalDivider from '../VerticalDivider/VerticalDivider';

const navbarMenu = {
  reviewer: {
    items: [
      {
        name: 'Logout',
        icon: 'logout',
        href: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
        active: false
      }
    ]
  },
  user: {
    items: [
      {
        name: 'Account',
        icon: 'account',
        href: `${process.env.REACT_APP_BILLING_URL}/account`,
        active: false
      },
      {
        name: 'Logout',
        icon: 'logout',
        href: process.env.REACT_APP_LOGOUT_REDIRECT_URL,
        active: false
      }
    ]
  }
};

export default function PageHeader() {
  const { logout } = useAuth0();

  const dispatch = useAppDispatch();

  const organization = useSelector((state: RootState) => state.user.org);

  const userMe = useSelector((state: RootState) => state.user.user);

  const currentUser = useSelector((state: RootState) => state.user.current_user);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const ref = useOutsideClick(() => {
    setIsOpen(false);
  });

  const handleClick = () => {
    setIsOpen((prev) => !prev);
  };

  const handleClickNavigate = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (e.currentTarget.id.toLowerCase() === 'logout') {
      logout({
        logoutParams: {
          returnTo: process.env.REACT_APP_LOGOUT_REDIRECT_URL
        }
      });
      localStorage.clear();
      window.location.href = e.currentTarget.value;
    } else {
      window.location.href = e.currentTarget.value;
    }
  };

  useEffect(() => {
    dispatch(userActions.fetchCurrentUser());
  }, []);

  const menu = currentUser?.role === 'USER' ? navbarMenu.user.items : navbarMenu.reviewer.items;
  const isUser = currentUser?.role === 'USER';

  return (
    <>
      <PageHeaderContainer className={`dash-menubar ${isUser ? 'fixed w-full' : ''}`}>
        <div className="leftbar">
          {organization?.image ? (
            <img
              data-testid="company-image"
              className="menu-item company-image"
              referrerPolicy="no-referrer"
              src={organization.image}
            />
          ) : null}
        </div>
        <div className="rightbar">
          {userMe ? (
            <>
              <VerticalDivider />

              <div ref={ref} className="page-header__dropdown-menu">
                <UserInfo user={userMe} onClick={handleClick} />
                {/* list */}
                {isOpen && (
                  <div className="page-header__dropdown-menu__content-menu-container">
                    {menu.map((item) => (
                      <button
                        key={item.name}
                        id={item.name}
                        className="page-header__dropdown-menu__item-content"
                        value={item.href}
                        onClick={handleClickNavigate}>
                        <Icon name={item.icon as keyof typeof icons} width={16} height={16} />

                        <p className="page-header__dropdown-menu__text">{item.name}</p>
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </>
          ) : null}
        </div>
      </PageHeaderContainer>
      {isUser && <div className="w-full h-[72px]" />}
    </>
  );
}
