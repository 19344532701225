import { gql } from '@apollo/client';

export const CREATE_TEMPLATE_BY_ORGANIZATION_ID = gql`
  mutation createDeepdocTemplate($organizationId: ID, $name: String!, $template: String!) {
    createDeepdocTemplate(
      input: { name: $name, organizationId: $organizationId, template: $template }
    ) {
      id
      editable
      name
      organizationId
      template
    }
  }
`;

export const UPDATE_TEMPLATE_BY_ORGANIZATION_ID = gql`
  mutation updateDeepdocTemplate(
    $templateId: ID!
    $organizationId: ID
    $name: String!
    $template: String!
  ) {
    updateDeepdocTemplate(
      id: $templateId
      input: { name: $name, organizationId: $organizationId, template: $template }
    ) {
      id
      editable
      name
      organizationId
      template
    }
  }
`;

export const APPLY_TEMPLATE_BY_ORGANIZATION_ID = gql`
  mutation applyDeepdocTemplate($deepDocJobId: ID!, $templateId: ID!) {
    applyDeepdocTemplate(deepDocJobId: $deepDocJobId, templateId: $templateId) {
      id
      createdAt
    }
  }
`;

export const DELETE_TEMPLATE_BY_ORGANIZATION_ID = gql`
  mutation deleteDeepdocTemplate($templateId: ID!) {
    deleteDeepdocTemplate(id: $templateId)
  }
`;
