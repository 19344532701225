import type { ActionReducerMapBuilder, CombinedState, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { TEMPLATE_NAME_LENGTH, TEMPLATE_SECTION_NAME_LENGTH } from 'common/constants';
import {
  applyTemplatesByOrganisationId,
  deleteTemplatesByOrganisationId,
  getDocTypes,
  getTemplatesByOrganisationId,
  updateTemplatesByOrganisationId
} from 'lib/apis/template';
import type { DeepDocDocumentType, DeepdocTemplate } from 'lib/graphql/__generated__/graphql';

import type { RootState } from '..';

const name = 'template';

export type ITemplateSection = {
  id?: string;
  format: string;
  rank: string;
  title: string;
  types: { rank: string; type: string }[];
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type CustomDeepdocTemplate = Omit<DeepdocTemplate, 'template'> & {
  template: {
    sections: ITemplateSection[];
    cover_page: {
      completionDateEnabled: boolean;
      deepdoc_logo: boolean;
      image: string;
      enabled: boolean;
    };
  };
};

interface IInitialState {
  loading: boolean;
  data: CustomDeepdocTemplate[];
  original: DeepdocTemplate[];
  docTypes: DeepDocDocumentType[];
  selectedTemplate?: string;
}

function createInitialState() {
  return {
    loading: true,
    data: [],
    original: [],
    docTypes: []
  };
}

function createExtraActions() {
  return {
    fetchTemplatesByOrganization: createAsyncThunk<
      {
        original: DeepdocTemplate[];
        current: CustomDeepdocTemplate[];
        docTypes: DeepDocDocumentType[];
      },
      { organizationId: string },
      { state: RootState }
    >('template/fetchTemplatesByOrganization', async ({ organizationId }, { rejectWithValue }) => {
      try {
        const result = await getTemplatesByOrganisationId(organizationId);
        const docTypesResponse = await getDocTypes();
        const docTypes = docTypesResponse.data.listDocTypes?.data as DeepDocDocumentType[];
        const currentTemplates = (result.data.listTemplates as DeepdocTemplate[]).map((item) => ({
          ...item,
          template: JSON.parse(item.template)
        }));
        return { original: result.data.listTemplates, current: currentTemplates, docTypes };
      } catch (err: unknown) {
        return rejectWithValue(err);
      }
    }),
    saveTemplate: createAsyncThunk<DeepdocTemplate, { templateId: string }, { state: RootState }>(
      'template/saveTemplate',
      async ({ templateId }, { rejectWithValue, getState }) => {
        try {
          const templateState = getState().template;
          const templateObj = templateState.data.find((templ) => templ.id === templateId);


          if (!templateObj) return;
          const {
            organizationId,
            name: templateName,
            template
          } = templateObj as CustomDeepdocTemplate;

          //Template name validation before saving
          if(!templateName || templateName.length > TEMPLATE_NAME_LENGTH) {
            return;
          }

          const isValidTemplateSection = template.sections.reduce((acc, section) => {
            if(!section.title || section.title.length > TEMPLATE_SECTION_NAME_LENGTH) {
              return false
            }
            return acc
          }, true)

          //Template section name validation before saving
          if(!isValidTemplateSection) {
            return;
          }

          const result = await updateTemplatesByOrganisationId(
            templateId,
            organizationId,
            templateName,
            JSON.stringify(template)
          );
          return result.data.updateDeepdocTemplate;
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    applyTemplate: createAsyncThunk<DeepdocTemplate, { templateId: string }, { state: RootState }>(
      'template/applyTemplate',
      async ({ templateId }, { rejectWithValue, getState }) => {
        try {
          const jobId = getState().job.job?.id;
          const result = await applyTemplatesByOrganisationId(templateId, String(jobId));
          return result.data;
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    deleteTemplate: createAsyncThunk<
      { isdeleted: boolean; templateId: string },
      { templateId: string },
      { state: RootState }
    >('template/deleteTemplate', async ({ templateId }, { rejectWithValue }) => {
      try {
        const result = await deleteTemplatesByOrganisationId(String(templateId));
        return { isdeleted: result.data.deleteDeepdocTemplate, templateId };
      } catch (err: unknown) {
        return rejectWithValue(err);
      }
    }),
    checkTemplateChanges: createAsyncThunk<boolean, undefined, { state: RootState }>(
      'template/checkTemplateChanges',
      async (_: undefined, { rejectWithValue, getState }) => {
        try {
          const templateState = getState().template;
          const currentTemplateId = templateState.selectedTemplate;
          const originalTemplateStringifyVersion = JSON.stringify(
            templateState.original.find((ori) => ori.id === currentTemplateId)
          );
          const currentTemplate = templateState.data.find((ori) => ori.id === currentTemplateId);
          const currentTemplateStringifyVersion = JSON.stringify({
            ...currentTemplate,
            template: JSON.stringify(currentTemplate?.template)
          });
          return originalTemplateStringifyVersion !== currentTemplateStringifyVersion;
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    )
  };
}

export const templateInitialState: IInitialState = createInitialState();
const extraActions = createExtraActions();

const createExtraReducers = (builder: ActionReducerMapBuilder<IInitialState>) => {
  const { fulfilled: fetchTemplatesByOrganizationFulfilled } =
    extraActions.fetchTemplatesByOrganization;
  const { fulfilled: saveTemplateFulfilled } = extraActions.saveTemplate;
  const { fulfilled: deleteTemplateFulfilled } = extraActions.deleteTemplate;

  builder.addCase(
    fetchTemplatesByOrganizationFulfilled,
    (
      state: CombinedState<IInitialState>,
      action: PayloadAction<{
        original: DeepdocTemplate[];
        current: CustomDeepdocTemplate[];
        docTypes: DeepDocDocumentType[];
      }>
    ) => {
      const { original, current, docTypes } = action.payload;
      state.data = current;
      state.original = original;
      state.docTypes = docTypes;
      if (!state.selectedTemplate) {
        // state.selectedTemplate = current.find((templ) => templ.name === 'Default')?.id
        state.selectedTemplate = current[0]?.id;
      }
      state.loading = false;
    }
  );
  builder.addCase(
    saveTemplateFulfilled,
    (state: CombinedState<IInitialState>, action: PayloadAction<DeepdocTemplate|undefined>) => {
      const template = action.payload;
      if(!template) return;
      let canFind = false;
      state.original = [...state.original].map((ori) => {
        if (ori.id === template.id) {
          canFind = true;
          return template;
        }
        return ori;
      });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (!canFind) {
        state.original = [template, ...state.original];
      }
    }
  );
  builder.addCase(
    deleteTemplateFulfilled,
    (
      state: CombinedState<IInitialState>,
      action: PayloadAction<{ isdeleted: boolean; templateId: string }>
    ) => {
      const { isdeleted, templateId } = action.payload;
      if (isdeleted) {
        const deletedIndex = state.data.findIndex((ori) => ori.id === templateId);
        state.data = state.data.filter((dt) => dt.id !== templateId);
        state.original = state.original.filter((ori) => ori.id !== templateId);
        const nextSelectedIndex = deletedIndex - 1 < 0 ? 0 : deletedIndex - 1;
        state.selectedTemplate = state.data[nextSelectedIndex].id;
      }
    }
  );
};

const slice = createSlice({
  name,
  initialState: templateInitialState,
  extraReducers: createExtraReducers,
  reducers: {
    setSelectedTemplate(state, action: PayloadAction<string>) {
      state.selectedTemplate = action.payload;
    },
    setSectionName(
      state,
      action: PayloadAction<{ templateId: string; sectionId: string; value: string }>
    ) {
      const { templateId, sectionId, value } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      const sectionIndex = template?.template.sections.findIndex((sect) => sect.id === sectionId);
      if (template?.template.sections && sectionIndex !== undefined) {
        template.template.sections[sectionIndex] = {
          ...template.template.sections[sectionIndex],
          title: value
        };
      }
    },
    setSectionFormat(
      state,
      action: PayloadAction<{ templateId: string; sectionId: string; value: string }>
    ) {
      const { templateId, sectionId, value } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      const section = template?.template.sections.find((sect) => sect.id === sectionId);
      if (section?.format) {
        section.format = value;
      }
    },
    addTemplate(state, action: PayloadAction<DeepdocTemplate>) {
      const template = action.payload;
      const parseTemplate = {
        ...template,
        template: JSON.parse(template.template)
      } as CustomDeepdocTemplate;
      state.data = [parseTemplate, ...state.data];
      state.original = [template, ...state.original];
    },
    updateTemplateName(state, action: PayloadAction<{ templateId: string; value: string }>) {
      const { templateId, value } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      if (template) template.name = value;
    },
    discardTemplateChanges(state, action: PayloadAction<string>) {
      const templateId = action.payload;
      const originalTemplate = state.original.find((templ) => templ.id === templateId);
      const newTemplate = {
        ...originalTemplate,
        template: JSON.parse(String(originalTemplate?.template))
      } as CustomDeepdocTemplate;
      const templateIndex = state.data.findIndex((templ) => templ.id === templateId);
      if (state.data[templateIndex]) {
        state.data[templateIndex] = newTemplate;
      }
    },
    addSectionToTemplate(
      state,
      action: PayloadAction<{ templateId: string; section: ITemplateSection }>
    ) {
      const { templateId, section: newSection } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      if (template?.template) {
        const ttemplate = template.template;
        const sections = ttemplate.sections;
        template.template.sections = [newSection, ...sections];
      }
    },
    deleteSection(state, action: PayloadAction<{ templateId: string; sectionId: string }>) {
      const { templateId, sectionId } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      if (template?.template) {
        const ttemplate = template.template;
        const sections = ttemplate.sections;
        template.template.sections = sections.filter((sect) => sect.id !== sectionId);
      }
    },
    updateIsDeepdocLogo(
      state,
      action: PayloadAction<{ isDeepdocLogo: boolean; templateId: string }>
    ) {
      const { isDeepdocLogo, templateId } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      if (template?.template.cover_page) {
        template.template.cover_page.deepdoc_logo = isDeepdocLogo;
      }
    },
    updateCoverLogo(state, action: PayloadAction<{ logo: string; templateId: string }>) {
      const { logo, templateId } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      if (template?.template.cover_page) {
        template.template.cover_page.image = logo;
      }
    },
    addTypeToTemplateSection(
      state,
      action: PayloadAction<{ templateId: string; sectionId: string; type: string }>
    ) {
      const { sectionId, templateId, type } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      const section = template?.template.sections.find((sect) => sect.id === sectionId);
      if (section?.types) {
        section.types = [...section.types, { rank: 'aaaaa', type }];
      }
    },
    removeTypeFromTemplateSection(
      state,
      action: PayloadAction<{ templateId: string; sectionId: string; type: string }>
    ) {
      const { sectionId, templateId, type } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      const section = template?.template.sections.find((sect) => sect.id === sectionId);
      if (section?.types) {
        section.types = section.types.filter((typ) => typ.type !== type);
      }
    },
    moveTypeToTemplateSection(
      state,
      action: PayloadAction<{
        templateId: string;
        sectionId: string;
        toSectionId: string;
        type: { rank: string; type: string };
      }>
    ) {
      const { sectionId, templateId, type, toSectionId } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      const oldSection = template?.template.sections.find((sect) => sect.id === sectionId);
      const newSection = template?.template.sections.find((sect) => sect.id === toSectionId);
      if (oldSection?.types) {
        oldSection.types = oldSection.types.filter((typ) => typ.type !== type.type);
      }
      if (newSection?.types) {
        newSection.types = [...newSection.types, type];
      }
    },
    updateTemplate(
      state,
      action: PayloadAction<{ templateId: string; sections: ITemplateSection[] }>
    ) {
      const { templateId, sections } = action.payload;
      const template = state.data.find((templ) => templ.id === templateId);
      if (template?.template) {
        template.template.sections = sections;
      }
    }
  }
});

// exports

export const templateActions = { ...slice.actions, ...extraActions };
export const templateReducer = slice.reducer;
