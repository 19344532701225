import { lazy } from 'react';

import { ProtectedRoute } from 'lib/auth/ProtectedRoute';
import { UserRole } from 'lib/graphql/__generated__/graphql';
import { Navigate } from 'react-router-dom';

const FileReview = lazy(async () => import('pages/FileReview'));
const FileDocumentPage = lazy(async () => import('pages/FileDocuments'));
const JobPage = lazy(async () => import('pages/Jobs'));
const TeamPage = lazy(async () => import('pages/Team'));
const ReviewerDashboardPage = lazy(async () => import('pages/ReviewerDashboard'));
const ServicePage = lazy(async () => import('pages/Services'));
const Summary = lazy(async () => import('pages/Summary'));
const Download = lazy(async () => import('pages/Download'));
// const SummaryDownload = lazy(async () => import('pages/SummaryDownload'));
const ICSRSetupPage = lazy(async () => import('pages/ICSRSetup'));
const MROSetupPage = lazy(async () => import('pages/MROSetup'));
const OrganizationPage = lazy(async () => import('pages/Organizations'));

export const protectedUserRoutes = [
  {
    path: '/',
    element: <ProtectedRoute allowedRole={[UserRole.User]} />,
    children: [
      { path: '/services', element: <ServicePage /> },
      { path: '/services/setup/MRO', element: <MROSetupPage /> },
      { path: '/services/setup/ICSR', element: <ICSRSetupPage /> },
      { path: '/summary/:jobId', element: <Summary /> },
      { path: '/download/:jobId', element: <Download /> },
      { path: '/', element: <Navigate to="/services" /> }
    ]
  }
];
export const protectedReviewerRoutes = [
  {
    path: '/',
    element: <ProtectedRoute allowedRole={[UserRole.Reviewer, UserRole.ProjectManager]} />,
    children: [
      { path: '/jobs', element: <JobPage /> },
      { path: '/team', element: <TeamPage /> },
      { path: '/organization', element: <OrganizationPage /> },
      { path: '/dee-documents/:jobId', element: <FileDocumentPage /> },
      { path: '/dee-review/:jobId/:sliceId', element: <FileReview /> },
      { path: '/summary/:jobId/:sliceId', element: <Summary /> },

      // TODO: check if dashboard are root / not
      { path: '/', element: <Navigate to="/jobs" /> },
      { path: '/dashboard', element: <ReviewerDashboardPage /> }
    ]
  }
];
