import apolloClient from 'lib/graphql/apolloClient';
import {
  APPLY_TEMPLATE_BY_ORGANIZATION_ID,
  CREATE_TEMPLATE_BY_ORGANIZATION_ID,
  DELETE_TEMPLATE_BY_ORGANIZATION_ID,
  UPDATE_TEMPLATE_BY_ORGANIZATION_ID
} from 'lib/graphql/mutations/template.mutation';
import {
  GET_LIST_DOC_TYPES,
  GET_TEMPLATES_BY_ORGANIZATION_ID
} from 'lib/graphql/queries/template.query';

export const getTemplatesByOrganisationId = async (organizationId: string) => {
  const result = await apolloClient.query({
    query: GET_TEMPLATES_BY_ORGANIZATION_ID,
    variables: { organizationId },
    fetchPolicy: 'no-cache'
  });
  return result;
};

export const createTemplatesByOrganisationId = async (
  organizationId: string,
  name: string,
  template: string
) => {
  const result = await apolloClient.mutate({
    mutation: CREATE_TEMPLATE_BY_ORGANIZATION_ID,
    variables: { organizationId, template, name },
    fetchPolicy: 'no-cache'
  });
  return result;
};

export const updateTemplatesByOrganisationId = async (
  templateId: string,
  organizationId: string,
  name: string,
  template: string
) => {
  const result = await apolloClient.mutate({
    mutation: UPDATE_TEMPLATE_BY_ORGANIZATION_ID,
    variables: { templateId, organizationId, template, name },
    fetchPolicy: 'no-cache'
  });
  return result;
};

export const applyTemplatesByOrganisationId = async (templateId: string, deepDocJobId: string) => {
  const result = await apolloClient.mutate({
    mutation: APPLY_TEMPLATE_BY_ORGANIZATION_ID,
    variables: { deepDocJobId, templateId },
    fetchPolicy: 'no-cache'
  });
  return result;
};

export const deleteTemplatesByOrganisationId = async (templateId: string) => {
  const result = await apolloClient.mutate({
    mutation: DELETE_TEMPLATE_BY_ORGANIZATION_ID,
    variables: { templateId },
    fetchPolicy: 'no-cache'
  });
  return result;
};

export const getDocTypes = async () => {
  const result = await apolloClient.query({
    query: GET_LIST_DOC_TYPES,
    fetchPolicy: 'no-cache'
  });
  return result;
};
