import type { ReactNode } from 'react';
import React from 'react';

export enum ButtonVariants {
  outlined = 'outlined',
  contained = 'contained'
}

export enum ButtonSize {
  medium = 'medium',
  small = 'small'
}

export enum ButtonStretch {
  normal = 'normal',
  full = 'full'
}

export type ButtonType = 'cta' | 'negative' | 'secondary' | 'tint' | 'ghost' | 'disable';

export default function CustomButton({
  onClick,
  text,
  variant,
  type,
  size,
  stretch,
  disabled = false,
  className,
  children
}: {
  readonly onClick?: () => void;
  readonly text?: string;
  readonly stretch?: ButtonStretch;
  readonly variant?: ButtonVariants;
  readonly type?: ButtonType;
  readonly size?: ButtonSize;
  readonly disabled?: boolean;
  readonly className?: string;
  readonly children?: ReactNode;
}) {
  return (
    <button
      data-testid="custom-btn"
      className={`button ${type ?? 'cta'} ${size ?? ''} ${variant ?? ButtonVariants.contained} ${
        stretch ?? ButtonStretch.normal
      } ${className ?? ''}`}
      onClick={() => onClick?.()}
      disabled={disabled}>
      {children ?? text}
    </button>
  );
}
