import React from 'react';

import { ReactComponent as AccountIcon } from 'common/assets/icons/account.svg';
import { ReactComponent as AngleDownIcon } from 'common/assets/icons/angle_down.svg';
import { ReactComponent as AngleLeftIcon } from 'common/assets/icons/angle_left.svg';
import { ReactComponent as AngleRightIcon } from 'common/assets/icons/angle_right.svg';
import { ReactComponent as AngleUpIcon } from 'common/assets/icons/angle_up.svg';
import { ReactComponent as ArrowLeftIcon } from 'common/assets/icons/arrow_left.svg';
import { ReactComponent as ArrowRightLongIcon } from 'common/assets/icons/arrow_right_long.svg';
import { ReactComponent as AtoZDownIcon } from 'common/assets/icons/atoz_down.svg';
import { ReactComponent as AtoZUpIcon } from 'common/assets/icons/atoz_up.svg';
import { ReactComponent as CalendarIcon } from 'common/assets/icons/calendar.svg';
import { ReactComponent as CheckIcon } from 'common/assets/icons/check.svg';
import { ReactComponent as CheckCircleIcon } from 'common/assets/icons/check_circle.svg';
import { ReactComponent as CheckGrayIcon } from 'common/assets/icons/check_gray.svg';
import { ReactComponent as CheckWhiteIcon } from 'common/assets/icons/check_white.svg';
import { ReactComponent as ClockIcon } from 'common/assets/icons/clock.svg';
import { ReactComponent as CloseIcon } from 'common/assets/icons/close.svg';
import { ReactComponent as TrashIcon } from 'common/assets/icons/delete.svg';
import { ReactComponent as TrashWarningIcon } from 'common/assets/icons/delete_warning.svg';
import { ReactComponent as DownloadIcon } from 'common/assets/icons/download.svg';
import { ReactComponent as EditIcon } from 'common/assets/icons/edit.svg';
import { ReactComponent as EyeIcon } from 'common/assets/icons/eye.svg';
import { ReactComponent as EyeWhiteIcon } from 'common/assets/icons/eye_white.svg';
import { ReactComponent as FilterIcon } from 'common/assets/icons/filter.svg';
import { ReactComponent as GiftGreenIcon } from 'common/assets/icons/gift_green.svg';
import { ReactComponent as GridViewIcon } from 'common/assets/icons/grid-view.svg';
import { ReactComponent as HourGlassIcon } from 'common/assets/icons/hourglass.svg';
import { ReactComponent as InfoSIcon } from 'common/assets/icons/info_s.svg';
import { ReactComponent as JobDoneIcon } from 'common/assets/icons/job_is_done.svg';
import { ReactComponent as OptionIcon } from 'common/assets/icons/kebab.svg';
import { ReactComponent as ListViewIcon } from 'common/assets/icons/list_view.svg';
import { ReactComponent as LoaderIcon } from 'common/assets/icons/loader.svg';
import { ReactComponent as LoaderGrayIcon } from 'common/assets/icons/loader_gray.svg';
import { ReactComponent as LockIcon } from 'common/assets/icons/lock.svg';
import { ReactComponent as LogoutIcon } from 'common/assets/icons/logout.svg';
import { ReactComponent as ManualIcon } from 'common/assets/icons/manual_icon.svg';
import { ReactComponent as NoDuplicateIcon } from 'common/assets/icons/no-duplicates.svg';
import { ReactComponent as PlusIcon } from 'common/assets/icons/plus.svg';
import { ReactComponent as RotateIcon } from 'common/assets/icons/rotate.svg';
import { ReactComponent as SearchIcon } from 'common/assets/icons/search.svg';
import { ReactComponent as SettingIcon } from 'common/assets/icons/settings.svg';
import { ReactComponent as SquareDashedPointerIcon } from 'common/assets/icons/square-dashed-pointer.svg';
import { ReactComponent as TemplateIcon } from 'common/assets/icons/template.svg';
import { ReactComponent as UploadIcon } from 'common/assets/icons/upload.svg';
import { ReactComponent as WaitingIcon } from 'common/assets/icons/waiting.svg';
import { ReactComponent as WarningIcon } from 'common/assets/icons/warning.svg';
import { ReactComponent as WarningRoundIcon } from 'common/assets/icons/warning_round.svg';
import { ReactComponent as Zero2oneDownIcon } from 'common/assets/icons/zero2one_down.svg';
import { ReactComponent as Zero2oneUpIcon } from 'common/assets/icons/zero2one_up.svg';
import { ReactComponent as ZoomIcon } from 'common/assets/icons/zoom.svg';

export const icons = {
  account: AccountIcon,
  eye: EyeIcon,
  'eye-white': EyeWhiteIcon,
  download: DownloadIcon,
  close: CloseIcon,
  filter: FilterIcon,
  zero2one_up: Zero2oneUpIcon,
  zero2one_down: Zero2oneDownIcon,
  atoz_up: AtoZUpIcon,
  atoz_down: AtoZDownIcon,
  angle_down: AngleDownIcon,
  angle_up: AngleUpIcon,
  angle_left: AngleLeftIcon,
  angle_right: AngleRightIcon,
  clock: ClockIcon,
  upload: UploadIcon,
  arrow_left: ArrowLeftIcon,
  'arrow-right-long': ArrowRightLongIcon,
  plus: PlusIcon,
  check: CheckIcon,
  'check-gray': CheckGrayIcon,
  'grid-view': GridViewIcon,
  gift_green: GiftGreenIcon,
  info_s: InfoSIcon,
  list_view: ListViewIcon,
  rotate: RotateIcon,
  loader: LoaderIcon,
  'loader-gray': LoaderGrayIcon,
  logout: LogoutIcon,
  zoom: ZoomIcon,
  edit: EditIcon,
  option: OptionIcon,
  trash: TrashIcon,
  'trash-warning': TrashWarningIcon,
  'warning-round': WarningRoundIcon,
  lock: LockIcon,
  warning: WarningIcon,
  calendar: CalendarIcon,
  'no-duplicate': NoDuplicateIcon,
  manual: ManualIcon,
  'job-done': JobDoneIcon,
  'check-white': CheckWhiteIcon,
  'search': SearchIcon,
  'square-dashed': SquareDashedPointerIcon,
  setting: SettingIcon,
  hourglass: HourGlassIcon,
  'check-circle': CheckCircleIcon,
  template: TemplateIcon,
  waiting: WaitingIcon,
};

export default function Icon({
  name,
  size,
  ...props
}: { readonly name: keyof typeof icons; readonly size?: number } & React.SVGProps<SVGSVGElement>) {
  const IconComponent = icons[name];
  return <IconComponent width={size ?? 18} height={size ?? 18} {...props} />;
}
