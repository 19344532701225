export const services = [
  {
    name: 'Medical records organization (US)',
    description:
      'Organize medical records by sorting chronologically, removing duplicates, building a table of contents and bookmarks, and classifying each record into one of 30+ types and optionally summarize medical records by identifying medically significant procedures, diagnoses, determinations and conclusions and summarizing them into a short written narrative.',
    eta: '24 hours',
    items: [
      {
        text: 'HIPAA compliant'
      },
      {
        text: 'Minimize file review cost'
      },
      {
        text: 'Reduce review errors'
      }
    ],
    disable: false,
    link: '/services/setup/MRO',
    reviews: ''
  },
  {
    name: 'Individual Case Study Report',
    description:
      'Analyze medical literature to determine if it contains the essential adverse event criteria for an individual case study report (ICSR) for a licenced drug',
    eta: '12 hours',
    items: [
      {
        text: 'Rapid response (< 12 hours)'
      },
      {
        text: 'Hyper scalable'
      },
      {
        text: 'Multi-lingual (> 10 languages)'
      },
      {
        text: 'HIPAA compliant'
      }
    ],
    disable: true,
    link: '/services/setup/MRO',
    reviews: '10K reviews'
  }
  // {
  //   name: 'Signals detection (Pharmacovigilance)',
  //   description:
  //     'Analyze medical literature to determine if safety signals might be present with a causal relationship between a licenced drug and an adverse event.',
  //   eta: '12 hours',
  //   items: [
  //     {
  //       text: 'Rapid response (< 12 hours)'
  //     },
  //     {
  //       text: 'Hyper scalable'
  //     },
  //     {
  //       text: 'Multi-lingual (> 10 languages)'
  //     },
  //     {
  //       text: 'HIPAA compliant'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '8K reviews'
  // },
  // {
  //   name: 'Medical Billing (CPT)',
  //   description:
  //     'Generate healthcare claims to submit to insurance companies based on notes taken by medical service providers - Current Procedural Terminology (CPT) approach.',
  //   eta: '12 hours',
  //   items: [
  //     {
  //       text: 'Rapid response (< 12 hours)'
  //     },
  //     {
  //       text: 'Maximize billing potential'
  //     },
  //     {
  //       text: 'Hyper scalable'
  //     },
  //     {
  //       text: 'HIPAA compliant'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '5K reviews'
  // },
  // {
  //   name: 'Medical Billing (DRG)',
  //   description:
  //     'Generate healthcare claims to submit to insurance companies based on notes taken by medical service providers - Diagnosis Related Group (DRG) approach.',
  //   eta: '12 hours',
  //   items: [
  //     {
  //       text: 'Rapid response (< 12 hours)'
  //     },
  //     {
  //       text: 'Maximize billing potential'
  //     },
  //     {
  //       text: 'Hyper scalable'
  //     },
  //     {
  //       text: 'HIPAA compliant'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '1K reviews'
  // },
  // {
  //   name: 'Real-time medical scribe (ICD)',
  //   description:
  //     'Document physician and patient interactions from audio recordings, identify International Statistical Classification of Diseases and Related Health Problems (ICD) codes, patient education outcomes and attribute physician time.',
  //   eta: '12 hours',
  //   items: [
  //     {
  //       text: 'Rapid response (< 12 hours)'
  //     },
  //     {
  //       text: 'Minimize billing cost'
  //     },
  //     {
  //       text: 'Minimize lost revenue opportunities'
  //     },
  //     {
  //       text: 'HIPAA compliant'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '4K reviews'
  // },
  // {
  //   name: 'Medical records summarization (US)',
  //   description:
  //     'Summarize medical records by identifying medically significant procedures, diagnoses, determinations and conclusions and summarizing them into a short written narrative.',
  //   eta: '24 hours',
  //   items: [
  //     {
  //       text: 'Rapid response (< 12 hours)'
  //     },
  //     {
  //       text: 'HIPAA compliant'
  //     },
  //     {
  //       text: 'Minimize file review cost'
  //     },
  //     {
  //       text: 'Reduce review errors'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '1M reviews'
  // },
  // {
  //   name: 'Insurance prior authorization (US)',
  //   description:
  //     'Provide clarity to insured parties as to whether a proposed procedure or therapeutic intervention is covered by their insurance policy.',
  //   eta: '24 hours',
  //   items: [
  //     {
  //       text: 'Reduce insurance administration cost ratios'
  //     },
  //     {
  //       text: 'HIPAA compliant'
  //     },
  //     {
  //       text: 'Increase customer satisfaction'
  //     },
  //     {
  //       text: 'Rapid response (<24 hours)'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '100K reviews'
  // },
  // {
  //   name: 'Address verification (UAE)',
  //   description:
  //     'Determine if a customer’s name and address is contained on provided evidence to satisfy Know Your Customer (KYC) requirements and an assessment if the evidence has been modified or tampered with in any way.',
  //   eta: '30 seconds',
  //   items: [
  //     {
  //       text: 'Reduce compliance costs'
  //     },
  //     {
  //       text: 'PCI compliant'
  //     },
  //     {
  //       text: 'Increase customer satisfaction'
  //     },
  //     {
  //       text: 'Real time response (<60 seconds)'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '5K reviews'
  // },
  // {
  //   name: 'Inauthentic social media analysis (Twitter)',
  //   description:
  //     'Discover and analyze inauthentic content amplification behavior for monitored accounts and provided Tweets to inform response and mitigation decisions in real time.',
  //   eta: '60 minutes',
  //   items: [
  //     {
  //       text: 'Mitigating the spread of disinformation'
  //     },
  //     {
  //       text: 'Reduce public confusion'
  //     },
  //     {
  //       text: 'Improve public health outcomes'
  //     },
  //     {
  //       text: 'Identify malicious social media users'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '50M reviews'
  // },
  // {
  //   name: 'Social media scanning (Pharmacovigilance)',
  //   description:
  //     'Monitor and analyze accounts and Tweets discussing pharmaceutical interventions such as vaccines and specific drug names for emerging trends and inauthentic amplification sources.',
  //   eta: '60 minutes',
  //   items: [
  //     {
  //       text: 'Mitigating the spread of disinformation'
  //     },
  //     {
  //       text: 'Reduce compliance risks and costs'
  //     },
  //     {
  //       text: 'Reduce reputational risks'
  //     },
  //     {
  //       text: 'Rapid response (< 60 seconds)'
  //     }
  //   ],
  //   disable: true,
  //   link: '/services/setup/MRO',
  //   reviews: '20M reviews'
  // }
];
