import React from 'react';

const PageLoader = () => {
  const loadingImg = 'https://cdn.auth0.com/blog/hello-auth0/loader.svg';

  return (
    <div className="page-layout">
      <div className="page_loader" data-testid="page_loader">
        <img className="loader" src={loadingImg} alt="Loading..." />
      </div>
    </div>
  );
};

export default PageLoader;
