import React from 'react';

import { ReactComponent as BrokenIcon } from 'common/assets/icons/broken.svg';
import CustomButton from 'features/ui/CustomButton/CustomButton';
import PageHeader from 'features/ui/PageHeader/PageHeader';
import { useNavigate } from 'react-router-dom';

export default function NotFoundPage({ cta }: { cta?: boolean }) {
  const navigate = useNavigate();

  const gotoServicePageHandler = () => {
    navigate('/services?type=all');
  };

  return (
    <div className="workspace">
      <PageHeader />
      <div className="notfound">
        <div className="newtime">
          <BrokenIcon width={26} />
          <div className="title">Page not found</div>
          <div className="subtitle">We’re sorry, the page you requested could not be found.</div>
        </div>
        {cta ? (
          <CustomButton text="Return to Services page" onClick={gotoServicePageHandler} />
        ) : null}
      </div>
    </div>
  );
}
