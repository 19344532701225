/* eslint-disable no-console */
import CustomButton from 'features/ui/CustomButton/CustomButton';

export const ErrorFallback = () => {
  return (
    <div
      className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
      role="alert">
      <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
      <CustomButton className="mt-4" onClick={() => window.location.assign(window.location.href)}>
        Refresh
      </CustomButton>
    </div>
  );
};
