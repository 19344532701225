/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { useEffect, useState } from 'react';

import type { User } from '@auth0/auth0-react';
import { useAuth0 } from '@auth0/auth0-react';
import { getCurrentUser } from 'lib/apis/user';
import { useDispatch } from 'react-redux';

import type { AppDispatch } from '../store';
import type { IUser } from '../store/user/userSlice';
import { userActions } from '../store/user/userSlice';

export default function useSystemAuth() {
  const dispatch = useDispatch<AppDispatch>();

  const { isLoading, user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [apiLoading, setApiLoading] = useState<boolean>(true);
  const [isUserAuthenticated, setIsUserAuthenticated] = useState<boolean>(false);
  const [isUserEmailVerified, setIsUserEmailVerified] = useState<boolean | null>(null);
  const [hasToken, setHasToken] = useState<boolean>(false);

  // Fetch the API Access token, when ready
  useEffect(() => {
    retrieveAccessTokenSilently();
  }, [getAccessTokenSilently]);

  // Load the user from the backend
  useEffect(() => {
    if (user && hasToken) {
      setIsUserAuthenticated(false);
      setApiLoading(true);
      getCurrentUser()
        .then(({ data }) => {
          const currentUser = data.currentUser;
          dispatch(userActions.addUser({ ...user, ...currentUser } as User & IUser));
          setApiLoading(false);
          setIsUserAuthenticated(true);
        })
        .catch(() => {
          setApiLoading(false);
          setIsUserEmailVerified(user.email_verified !== undefined ? user.email_verified : null);
        });
    }
    /**
     * if OAuth is done loading and the user is null,
     * then setting API loading to false, would allow proper redirection
     */
    if (!user && !isLoading) {
      setApiLoading(false);
    }
  }, [user, isLoading, hasToken]);

  const retrieveAccessTokenSilently = () => {
    getAccessTokenSilently()
      .then((data) => {
        localStorage.setItem('token', data);
        setHasToken(true);
      })
      .catch((error: any) => {
        console.log('what error === ', error);
        setApiLoading(false);
      });
  };

  return {
    isLoading: isLoading || apiLoading,
    isAuthenticated: isAuthenticated && isUserAuthenticated,
    isLoggedIn: !(isLoading || apiLoading) && user != null,
    isUserEmailVerified: isUserEmailVerified,
    user
  };
}
