import type { DeepDocJobWorkflowStatus, JobFilterInput } from 'lib/graphql/__generated__/graphql';
import apolloClient from 'lib/graphql/apolloClient';
import { UPDATE_DEEP_DOC_RECORD_STATUS_MUTATION } from 'lib/graphql/mutations/deepdoc.mutation';
import { ADD_FILES_TO_JOB_MUTATION } from 'lib/graphql/mutations/job.mutation';
import {
  ASSIGN_SLICE_MUTATION,
  PICK_UP_SLICE_MUTATION,
  REBUILD_DEEPDOC_PDF_MUTATION
} from 'lib/graphql/mutations/slice.mutation';
import {
  GET_JOBS,
  GET_JOB_BY_ID,
  GET_JOB_DATA_BY_ID,
  GET_JOB_DOWNLOAD_URL,
  GET_JOB_STATUS_BY_ID
} from 'lib/graphql/queries/job.query';

export const getJobById = async (jobId: string, limit = 2, page = 2) => {
  const jobData = await apolloClient.query({
    query: GET_JOB_BY_ID,
    variables: { id: jobId, limit, offset: limit * (page - 1) },
    fetchPolicy: 'no-cache'
  });
  return jobData;
};

export const getJobDataById = async (jobId: string) => {
  const jobData = await apolloClient.query({
    query: GET_JOB_DATA_BY_ID,
    variables: { id: jobId },
    fetchPolicy: 'no-cache'
  });
  return jobData;
};

export const getJobStatusById = async (jobId: string, limit = 2, page = 2) => {
  const jobData = await apolloClient.query({
    query: GET_JOB_STATUS_BY_ID,
    variables: { id: jobId, limit, offset: limit * (page - 1) },
    fetchPolicy: 'no-cache'
  });
  return jobData;
};

export const addFilesToJob = async (jobId: string, files: string[]) => {
  const jobData = await apolloClient.mutate({
    mutation: ADD_FILES_TO_JOB_MUTATION,
    variables: { deepDocJobId: jobId, files },
    fetchPolicy: 'no-cache'
  });
  return jobData;
};

export const getAllJobs = async ({
  limit=2, 
  page=1, 
  filter={}
}: {limit:number; page: number; filter: JobFilterInput}) => {
  const jobsData = await apolloClient.query({
    query: GET_JOBS,
    variables: { limit, offset: limit * (page - 1), filter },
    fetchPolicy: 'no-cache'
  });
  return jobsData;
};

export const getJobDownloadUrl = async (jobId: string) => {
  const jobsData = await apolloClient.query({
    query: GET_JOB_DOWNLOAD_URL,
    variables: { id: jobId },
    fetchPolicy: 'no-cache'
  });
  return jobsData;
};

// TODO: use this for updating job level summary
/* 
 - [ ] QUALITY_CONTROL_SUMMARY
 - [ ] DONE
*/
export const updateDeepDocJobStatus = async (id: string, newStatus: DeepDocJobWorkflowStatus) => {
  const recordData = await apolloClient.mutate({
    mutation: UPDATE_DEEP_DOC_RECORD_STATUS_MUTATION, // FIXME
    variables: { id, newStatus },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const pickUpSlice = async (id: string) => {
  const recordData = await apolloClient.mutate({
    mutation: PICK_UP_SLICE_MUTATION,
    variables: { id },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const rebuildDeepdocPdf = async (jobId: string) => {
  const recordData = await apolloClient.mutate({
    mutation: REBUILD_DEEPDOC_PDF_MUTATION,
    variables: { jobId },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};

export const assignSlice = async (sliceId: string, userId: string) => {
  const recordData = await apolloClient.mutate({
    mutation: ASSIGN_SLICE_MUTATION,
    variables: { sliceId, userId },
    fetchPolicy: 'no-cache'
  });
  return recordData;
};
