import { graphql } from '../__generated__/gql';

export const GET_CURRENT_USER = graphql(`
  query currentUser {
    currentUser {
      id
      email
      name
      role
      organization {
        id
        name
      }
    }
  }
`);

export const GET_ALL_USER = graphql(`
  query users ($limit: Int!, $offset: Int!, $filter: UserFilterInput!) {
    users(limit: $limit, offset: $offset, filter: $filter) {
      data {
        id
        email
        name
        role
        status
      }
      offsetInfo {
        start
        total
        next
        prev
        count
      }
    }
  }
`);

export const GET_ALL_ORGANIZATION = graphql(`
  query organizations {
    organizations(limit: 200, offset: 0) {
      data {
        id
        name
      }
    }
  }
`);
