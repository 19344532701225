/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CombinedState, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AAICOLogo from 'common/assets/icons/aaico_icon.svg';
import type { IHSLColor } from 'common/utils/hslColor';
import { getAllOrganizations, getAllUsers, getCurrentUser } from 'lib/apis/user';
import type { Organization, User, UserFilterInput, UserRole } from 'lib/graphql/__generated__/graphql';

export enum Role {
  Admin = 'admin',
  User = 'user'
}

export interface IUser {
  readonly given_name?: string;
  readonly family_name?: string;
  readonly nickname?: string;
  readonly name?: string;
  readonly picture?: string;
  readonly locale?: string;
  readonly updated_at?: string;
  readonly email: string;
  readonly id: string;
  readonly role: Role | UserRole;
  readonly active?: boolean;
  readonly is_bot?: boolean;
  readonly color?: IHSLColor;
}

export interface ICurrentUser {
  role: string;
}

export interface IOrganization {
  readonly id: string;
  readonly name: string;
  readonly image?: string;
}

interface IInitialState {
  readonly org?: IOrganization;
  user: IUser | undefined;
  users?: User[];
  organizations?: Organization[];
  readonly loading: boolean;
  current_user: ICurrentUser | undefined;
}

function createInitialState(): IInitialState {
  return {
    org: {
      id: '1',
      name: 'AAICO',
      image: AAICOLogo
    },
    user: undefined,
    current_user: undefined,
    loading: true
  };
}

function createExtraActions() {
  return {
    fetchCurrentUser: createAsyncThunk('user/fetchCurrentUser', async (_, { rejectWithValue }) => {
      try {
        const user = await getCurrentUser();
        return { user: user.data.currentUser };
      } catch (err: unknown) {
        return rejectWithValue(err);
      }
    }),
    fetchAllUsers: createAsyncThunk('user/fetchAllUsers', async ({ limit = 200, offset = 0, filter = {} }: { limit?: number, offset?: number, filter?: UserFilterInput }, { rejectWithValue }) => {
      try {
        const data = await getAllUsers({ filter, limit, offset });
        return data.data.users?.data;
      } catch (err: unknown) {
        return rejectWithValue(err);
      }
    }),
    fetchAllOrganizations: createAsyncThunk('user/fetchAllOrganizations', async (_, { rejectWithValue }) => {
      try {
        const data = await getAllOrganizations();
        return data.data.organizations.data;
      } catch (err: unknown) {
        return rejectWithValue(err);
      }
    })
  };
}

const extraActions = createExtraActions();

const createExtraReducers = (builder: any) => {
  const { fulfilled: fetchCurrentUserFulfilled } = extraActions.fetchCurrentUser;
  const { fulfilled: fetchAllUsersFulfilled } = extraActions.fetchAllUsers;
  const { fulfilled: fetchAllOrganizationsFulfilled } = extraActions.fetchAllOrganizations;

  /**
   * GET CURRENT USER
   */
  builder.addCase(
    fetchCurrentUserFulfilled,
    (
      state: CombinedState<IInitialState>,
      action: PayloadAction<{
        user: {
          __typename?: 'User';
          id: string;
          email?: string | null;
          name?: string | null;
          role: UserRole;
        };
      }>
    ) => {
      state.current_user = { ...state.current_user, role: action.payload.user.role };
    }
  );

  /**
   * GET ALL USERS
   */
  builder.addCase(
    fetchAllUsersFulfilled,
    (
      state: CombinedState<IInitialState>,
      action: PayloadAction<User[]>
    ) => {
      state.users = action.payload;
    }
  );

  /**
   * GET ALL ORGANIZATIONS
   */
  builder.addCase(
    fetchAllOrganizationsFulfilled,
    (
      state: CombinedState<IInitialState>,
      action: PayloadAction<Organization[]>
    ) => {
      state.organizations = action.payload;
    }
  );
};

const slice = createSlice({
  name: 'user',
  initialState: createInitialState(),
  extraReducers: createExtraReducers,
  reducers: {
    addUser(state, action: PayloadAction<IUser>) {
      state.user = { ...state.user, ...action.payload };
    }
  }
});

// exports
export const userActions = { ...slice.actions, ...extraActions };
export const userReducer = slice.reducer;
