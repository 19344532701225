import type { ChronologicalSortType, ISection } from 'common/store/summary/type';
import _ from 'lodash';

export const sortingTableContent = (data: ISection[], strategy: ChronologicalSortType) => {
  switch (strategy) {
    case 'Oldest':
    case 'Newest': {
      const orders = strategy === 'Newest' ? 'desc' : 'asc';
      return _.orderBy(
        data.map((section) => ({
          ...section,
          items: _.orderBy(section.items, (i) => new Date(i.date), orders)
        })),
        (i) => i.createdAt,
        orders
      );
    }
  }
};
