/* eslint-disable no-console */
import type {
  DeepDocDocumentType,
  DeepDocJobBoundaryEntry,
  DeepDocRecordPage,
  JobSlice
} from 'lib/graphql/__generated__/graphql';
import { sortBy } from 'lodash';

export const pageIdPositionPairs = (pages: DeepDocRecordPage[]) =>
  pages.reduce<
    Record<
      string,
      Omit<DeepDocRecordPage, 'section'> & { pos: number; presignedUrl: string; section: string }
    >
  >((acc, page, index) => {
    if (!page.id) return acc;
    acc[page.id] = {
      ...page,
      pos: index + 1,
      presignedUrl: page.presignedUrl ?? '',
      section: String(page.section)
    };
    return acc;
  }, {});

export const extractBoundaries = (
  boundaries: DeepDocJobBoundaryEntry[],
  idPositionPairs: Record<string, { pos: number; presignedUrl: string }>
) =>
  boundaries
    .filter((ft) => (ft.pages?.length ?? 0) > 0)
    .map((boundary) => {
      let pagesInBoundaries = boundary.pages as DeepDocRecordPage[];

      const numberOfPageInBoundaries = pagesInBoundaries.length || 0;

      pagesInBoundaries = sortBy(pagesInBoundaries, ['rank']);

      //Extra filtering for invalid boundaries
      pagesInBoundaries = pagesInBoundaries.filter((page) =>
        Boolean(idPositionPairs[String(page.id)])
      );
      if (pagesInBoundaries.length === 0) {
        return { ...boundary };
      }

      const firstPageInBoundaries = pagesInBoundaries[0] || {};
      const lastPageInBoundaries = pagesInBoundaries[(numberOfPageInBoundaries || 1) - 1] || {};

      const firstPageInBoundariesIndex = idPositionPairs[String(firstPageInBoundaries.id)].pos;
      const lastPageInBoundariesIndex = idPositionPairs[String(lastPageInBoundaries.id)].pos;

      const range = `${firstPageInBoundariesIndex} - ${lastPageInBoundariesIndex}`;
      return { ...boundary, range };
    });

export const extractDocTypesBoundaries = (
  extractedBoundaries: DeepDocJobBoundaryEntry[],
  docTypes?: DeepDocDocumentType[]
) => {
  return extractedBoundaries.reduce<Record<string, { data: DeepDocJobBoundaryEntry[] }>>(
    (acc, boundary) => {
      if (!boundary.type) boundary.type = 'Unclassified';
      if (typeof acc[boundary.type] === 'undefined') {
        const docType = docTypes?.find((dT) => dT.name === boundary.type);
        acc[boundary.type] = { ...docType, data: [] };
      }
      acc[boundary.type].data.push(boundary);
      return acc;
    },
    {}
  );
};

export const filterBoundariesBySlice = (boundary?: DeepDocJobBoundaryEntry[], slice?: JobSlice) => {
  if (!slice || slice.meta === 'null') {
    return boundary;
  }
  const meta = JSON.parse(String(slice.meta));
  return boundary?.filter((bound) => meta.boundaries.includes(bound.id));
};
