import { graphql } from '../__generated__/gql';

export const CREATE_DEEPDOC_JOB_BOUNDARY_MUTATION = graphql(`
  mutation createDeepDocJobBoundaryEntry(
    $jobId: String!
    $pages: [DeepDocRecordPageRange!]!
    $type: String!
  ) {
    createDeepDocJobBoundaryEntry(input: { deepDocJobId: $jobId, pages: $pages, type: $type }) {
      id
      rank
      type
      completed
      reviewed
      dateOfService
      dateOfServiceChanged
      dateOfServiceReviewed
      facility
      facilityChanged
      facilityReviewed
      physician
      physicianChanged
      physicianReviewed
      pages {
        id
        rank
        section
      }
    }
  }
`);

export const UPDATE_DEEP_DOC_RECORD_BOUNDARY_MUTATION = graphql(`
  mutation updateDeepDocRecordBoundaryEntry(
    $completed: Boolean
    $dateOfService: String
    $facility: String
    $id: ID!
    $physician: String
    $pages: [DeepDocRecordPageRange!]
    $type: String
  ) {
    updateDeepDocRecordBoundaryEntry(
      input: {
        completed: $completed
        dateOfService: $dateOfService
        facility: $facility
        id: $id
        physician: $physician
        pages: $pages
        type: $type
      }
    ) {
      id
      rank
      type
      completed
      reviewed
      dateOfService
      dateOfServiceChanged
      dateOfServiceReviewed
      facility
      facilityChanged
      facilityReviewed
      physician
      physicianChanged
      physicianReviewed
      pages {
        id
        rank
        section
      }
    }
  }
`);

export const UPDATE_DEEP_DOC_RECORD_STATUS_MUTATION = graphql(`
  mutation updateDeepDocJobStatus($id: ID!, $newStatus: DeepDocJobWorkflowStatus!) {
    updateDeepDocJobStatus(input: { id: $id, newStatus: $newStatus }) {
      id
    }
  }
`);

export const UPDATE_SLICE_STATUS_MUTATION = graphql(`
  mutation updateSliceStatus($id: ID!, $newStatus: SliceStatus) {
    updateSliceStatus(id: $id, status: $newStatus) {
      id
    }
  }
`);

export const UPDATE_DEEP_DOC_RECORD_BOUNDARY_FIELD_MUTATION = graphql(`
  mutation updateDeepDocRecordBoundaryField(
    $id: ID!
    $field: DeepdocBoundaryReviewableField!
    $value: String
  ) {
    updateDeepDocRecordBoundaryField(input: { id: $id, field: $field, value: $value }) {
      id
    }
  }
`);

export const MARK_DEEP_DOC_RECORD_BOUNDARY_FIELD_AS_REVIEWED_MUTATION = graphql(`
  mutation markDeepDocRecordBoundaryFieldAsReviewed(
    $id: ID!
    $field: DeepdocBoundaryReviewableField!
  ) {
    markDeepDocRecordBoundaryFieldAsReviewed(input: { id: $id, field: $field }) {
      id
    }
  }
`);

export const MARK_DEEP_DOC_RECORD_BOUNDARY_AS_DUPLICATE_MUTATION = graphql(`
  mutation markDeepDocJobBoundaryAsDuplicate(
    $decision: DeepDocDuplicateDecision
    $duplicateId: ID
  ) {
    markDeepDocJobBoundaryAsDuplicate(decision: $decision, deepdocDuplicateId: $duplicateId) {
      id
    }
  }
`);

export const MOVE_DEEP_DOC_PAGES_TO_SECTION_MUTATION = graphql(`
  mutation moveDeepDocPagesToSection(
    $deepDocJob: ID!
    $pages: [ID!]
    $section: DeepDocPageSection!
  ) {
    moveDeepDocPagesToSection(
      input: { deepDocJob: $deepDocJob, pages: $pages, section: $section }
    ) {
      id
      presignedUrl
      rank
      section
    }
  }
`);

export const CREATE_DEEPDOC_JOB_RECORD_DUPLICATE_ENTRY = graphql(`
  mutation createDeepDocJobDuplicateEntry($boundaryA: ID, $boundaryB: ID, $deepdocJobId: ID) {
    createDeepDocJobDuplicateEntry(
      boundaryA: $boundaryA
      boundaryB: $boundaryB
      deepdocJobId: $deepdocJobId
    ) {
      id
      boundaryA
      boundaryB
      decision
      source
    }
  }
`);

export const SAVE_CLIENT_RESULT = graphql(`
  mutation saveClientResult($jobId: ID, $clientResult: String) {
    saveClientResult(jobId: $jobId, clientResult: $clientResult) {
      id
      clientResult
    }
  }
`);
