/* eslint-disable @typescript-eslint/no-explicit-any */
import type { CombinedState, PayloadAction } from '@reduxjs/toolkit';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { sortingTableContent } from 'common/utils/sort';
import {
  convertClientResultToSection,
  convertSectionToClientResult
} from 'common/utils/summaryUtil';
import { getDeepdocJobTOC, saveClientResult } from 'lib/apis/deepdoc';
import {
  DeepDocTaskType,
  type DeepDocJob,
  type DeepDocJobBoundaryEntry
} from 'lib/graphql/__generated__/graphql';
import { v4 } from 'uuid';

// import { mainDataExample } from './data';
import type {
  ChronologicalSortType,
  ICoverPage,
  ICoverPageData,
  IRow,
  ISection,
  ISummaryInitialState
} from './type';
import type { RootState } from '..';

function createInitialState(): ISummaryInitialState {
  return {
    mainTableContent: [],
    archiveTableContent: [],
    sortingStrategy: 'Newest',
    loading: true,
    coverPage: {
      completionDate: '',
      image: '',
      jobName: '',
      subtitle: '',
      includeDDLogo: true
    }
  };
}

function createExtraActions() {
  return {
    fetchJobToC: createAsyncThunk<unknown, { jobId: string }, { state: RootState }>(
      'document/fetchJobToC',
      async ({ jobId }, { getState, rejectWithValue }) => {
        try {
          const state = getState();
          const result = await getDeepdocJobTOC(jobId).then(({ data }) => data);
          const job = result.job as DeepDocJob;
          const clientResults = job.clientResult;
          const jobTitle = job.title;
          const completionDate = job.slaDatetime;
          const parsedClientResults = JSON.parse(clientResults ?? '');

          const sections = parsedClientResults?.sections ?? [];
          const archiveSections = parsedClientResults?.archive ?? [];

          const boundariesIdMap = state.document.boundaries?.reduce(
            (acc, boundary) => {
              acc[boundary.id as string] = boundary;
              return acc;
            },
            {} as Record<
              string,
              DeepDocJobBoundaryEntry & {
                range?: string | undefined;
              }
            >
          );
          const { sections: formattedSections, boundariesRecord } = convertClientResultToSection(
            sections,
            boundariesIdMap
          );
          const { sections: formattedArchiveSections, boundariesRecord: boundariesArchiveRecord } =
            convertClientResultToSection(archiveSections, boundariesIdMap);
          return {
            formattedSections,
            formattedArchiveSections,
            boundariesRecord: {
              ...boundariesRecord,
              ...boundariesArchiveRecord
            },
            parsedClientResults,
            jobTitle,
            completionDate
          };
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    updateClientResult: createAsyncThunk<
      unknown,
      { jobId: string; sections: ISection[]; archiveSections: ISection[] },
      { state: RootState }
    >(
      'document/updateClientResult',
      async ({ jobId, sections, archiveSections }, { getState, rejectWithValue }) => {
        try {
          const summaryStates = getState().summary;
          const parsedClientResults: any = summaryStates.parsedClientResults;
          const boundariesRecord = summaryStates.boundariesRecord;
          if (!boundariesRecord || !parsedClientResults) return {};
          const newParsedClientResults = { ...parsedClientResults };
          if (sections.length > 0) {
            const clientResultSections = convertSectionToClientResult(sections, boundariesRecord);
            newParsedClientResults.sections = clientResultSections;
          }
          if (archiveSections.length > 0) {
            const archiveClientResultSections = convertSectionToClientResult(
              archiveSections,
              boundariesRecord
            );
            newParsedClientResults.archive = archiveClientResultSections;
          }
          const response = await saveClientResult(jobId, JSON.stringify(newParsedClientResults));
          return { clientResult: response.data?.saveClientResult?.clientResult };
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    updateCoverPageData: createAsyncThunk<unknown, { jobId: string }, { state: RootState }>(
      'document/updateCoverPageData',
      async ({ jobId }, { getState, rejectWithValue }) => {
        try {
          const summaryStates = getState().summary;
          const parsedClientResults: any = summaryStates.parsedClientResults;
          const savedCoverPageData = summaryStates.coverPage;
          const updatedCoverPageData = {
            jobId,
            ...savedCoverPageData,
            deepdoc_logo: savedCoverPageData.includeDDLogo,
            title: savedCoverPageData.jobName
          };
          const newParsedClientResults = {
            ...parsedClientResults,
            cover_page: updatedCoverPageData
          };
          const response = await saveClientResult(jobId, JSON.stringify(newParsedClientResults));
          return { clientResult: response.data?.saveClientResult?.clientResult };
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    ),
    setDefaultCoverPageData: createAsyncThunk<unknown, { jobId: string }, { state: RootState }>(
      'document/setDefaultCoverPageData',
      async ({ jobId }, { rejectWithValue }) => {
        try {
          const result = await getDeepdocJobTOC(jobId).then(({ data }) => data);
          const job = result.job as DeepDocJob;
          const clientResults = job.clientResult;
          const jobTitle = job.title;
          const completionDate = job.slaDatetime;
          const parsedClientResults = JSON.parse(clientResults ?? '');
          if (job.taskType === DeepDocTaskType.OrganizeSummarize || parsedClientResults?.cover_page)
            return;
          const updatedCoverPageData = {
            jobId,
            completionDate,
            title: jobTitle
          };
          const newParsedClientResults = {
            ...parsedClientResults,
            cover_page: updatedCoverPageData
          };
          const response = await saveClientResult(jobId, JSON.stringify(newParsedClientResults));
          return { clientResult: response.data?.saveClientResult?.clientResult };
        } catch (err: unknown) {
          return rejectWithValue(err);
        }
      }
    )
  };
}

const extraActions = createExtraActions();

const createExtraReducers = (builder: any) => {
  const { pending: fetchJobToCPending, fulfilled: ffetchJobToCFilled } = extraActions.fetchJobToC;

  const { fulfilled: updateClientResultFilled } = extraActions.updateClientResult;

  builder.addCase(fetchJobToCPending, (state: CombinedState<ISummaryInitialState>) => {
    state.loading = true;
  });

  builder.addCase(
    ffetchJobToCFilled,
    (
      state: CombinedState<ISummaryInitialState>,
      action: PayloadAction<{
        formattedSections: ISection[];
        formattedArchiveSections: ISection[];
        boundariesRecord: Record<string, DeepDocJobBoundaryEntry>;
        parsedClientResults: any;
        jobTitle?: string;
        completionDate?: string;
      }>
    ) => {
      state.mainTableContent = action.payload.formattedSections;
      state.archiveTableContent = action.payload.formattedArchiveSections;
      state.boundariesRecord = action.payload.boundariesRecord;
      state.parsedClientResults = action.payload.parsedClientResults;
      const coverPageData = (action.payload.parsedClientResults?.cover_page ??
        {}) as ICoverPageData;
      state.coverPage = {
        completionDate: coverPageData.completionDate ?? action.payload.completionDate ?? '',
        image: coverPageData.image ?? '',
        image_name: coverPageData.image_name ?? '',
        jobName: coverPageData.title ?? action.payload.jobTitle ?? '',
        subtitle: coverPageData.subtitle ?? '',
        includeDDLogo: !!coverPageData.deepdoc_logo
      };
      state.loading = false;
    }
  );

  builder.addCase(
    updateClientResultFilled,
    (
      state: CombinedState<ISummaryInitialState>,
      action: PayloadAction<{ clientResult?: string | null }>
    ) => {
      const { clientResult } = action.payload;
      if (!clientResult) return;
      state.parsedClientResults = JSON.parse(clientResult);
      return state;
    }
  );
};

const slice = createSlice({
  name: 'summary',
  initialState: createInitialState(),
  extraReducers: createExtraReducers,
  reducers: {
    updateMainTableContent(state, action: PayloadAction<ISection[]>) {
      state.mainTableContent = action.payload;
    },
    updateArchiveTableContent(state, action: PayloadAction<ISection[]>) {
      state.archiveTableContent = action.payload;
    },
    addItemToArchiveTableContent(state, action: PayloadAction<{ item: IRow; sectionId: string }>) {
      const { item, sectionId } = action.payload;
      const sectionIndex = state.mainTableContent.findIndex(
        (container) => container.id === sectionId
      );
      if (sectionIndex === -1) return;
      //Remove item from section
      const itemSection = state.mainTableContent[sectionIndex];
      itemSection.items = itemSection.items.filter((it) => it.id !== item.id);
      //Add to archive
      const archiveSection = state.archiveTableContent.find(
        (section) => section.id === itemSection.id
      );
      if (archiveSection) {
        archiveSection.items.push(item);
      } else {
        state.archiveTableContent.push({ ...itemSection, items: [item] });
      }
    },
    moveItemToMainTableContent(state, action: PayloadAction<{ item: IRow; sectionId: string }>) {
      const { item, sectionId } = action.payload;
      const sectionIndex = state.archiveTableContent.findIndex(
        (container) => container.id === sectionId
      );
      if (sectionIndex === -1) return;
      //Remove item from archive section
      const itemSection = state.archiveTableContent[sectionIndex];
      itemSection.items = itemSection.items.filter((it) => it.id !== item.id);
      if (itemSection.items.length === 0) {
        state.archiveTableContent = state.archiveTableContent.filter(
          (container) => container.id !== itemSection.id
        );
      }
      //Add to main
      const mainSection = state.mainTableContent.find((section) => section.id === itemSection.id);
      if (mainSection) {
        mainSection.items.push(item);
      } else {
        state.mainTableContent.push({ ...itemSection, items: [item] });
      }
    },
    moveItemToSectionTableContent(
      state,
      action: PayloadAction<{ item: IRow; currentSectionId: string; nextSectionId: string }>
    ) {
      const { item, currentSectionId, nextSectionId } = action.payload;
      const currentSectionIndex = state.mainTableContent.findIndex(
        (container) => container.id === currentSectionId
      );
      const nextSectionIndex = state.mainTableContent.findIndex(
        (container) => container.id === nextSectionId
      );
      if (currentSectionIndex === -1 || nextSectionIndex === -1) return;
      //Remove item from current section
      const currentItemSection = state.mainTableContent[currentSectionIndex];
      currentItemSection.items = currentItemSection.items.filter((it) => it.id !== item.id);
      //Move item to another section
      const nextItemSection = state.mainTableContent[nextSectionIndex];
      nextItemSection.items.push(item);
    },
    moveSectionToArchive(state, action: PayloadAction<string>) {
      const sectionId = action.payload;
      const sectionIndex = state.mainTableContent.findIndex(
        (container) => container.id === sectionId
      );
      if (sectionIndex === -1) return;
      // Remove items from section
      const itemSection = state.mainTableContent[sectionIndex];
      const movingItems = [...itemSection.items];
      itemSection.items = [];
      //Add to archive
      const archiveSection = state.archiveTableContent.find(
        (section) => section.id === itemSection.id
      );
      if (archiveSection) {
        archiveSection.items.push(...movingItems);
      } else {
        state.archiveTableContent.push({ ...itemSection, items: movingItems });
      }
    },
    moveSectionToMain(state, action: PayloadAction<string>) {
      const sectionId = action.payload;
      const archiveSectionIndex = state.archiveTableContent.findIndex(
        (container) => container.id === sectionId
      );
      if (archiveSectionIndex === -1) return;
      // Remove items from archive
      const itemArchiveSection = state.archiveTableContent[archiveSectionIndex];
      const movingItems = [...itemArchiveSection.items];
      state.archiveTableContent = state.archiveTableContent.filter(
        (container) => container.id !== sectionId
      );
      //Add to main
      const mainSection = state.mainTableContent.find(
        (section) => section.id === itemArchiveSection.id
      );
      if (mainSection) {
        mainSection.items.push(...movingItems);
      } else {
        state.mainTableContent.push({ ...itemArchiveSection, items: movingItems });
      }
    },
    updateSectionName(state, action: PayloadAction<{ sectionId: string; name: string }>) {
      const { sectionId, name } = action.payload;
      const section = state.mainTableContent.find((container) => container.id === sectionId);
      if (section) section.name = name;
      const archive = state.archiveTableContent.find((container) => container.id === sectionId);
      if (archive) archive.name = name;
    },
    createNewSection(state, action: PayloadAction<{ name: string }>) {
      const { name } = action.payload;
      const newSection = {
        id: `container-${v4()}`,
        name,
        collapse: true,
        createdAt: new Date().toISOString(),
        items: []
      };
      state.mainTableContent.push(newSection);
    },
    setSortingStrategy(state, action: PayloadAction<ChronologicalSortType>) {
      state.sortingStrategy = action.payload;
      state.mainTableContent = sortingTableContent(state.mainTableContent, action.payload);
      state.archiveTableContent = sortingTableContent(state.archiveTableContent, action.payload);
    },
    setCoverPageData(state, action: PayloadAction<ICoverPage>) {
      state.coverPage = action.payload;
    }
  }
});

// exports
export const summaryActions = { ...slice.actions, ...extraActions };
export const summaryReducer = slice.reducer;
