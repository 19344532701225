import { useAuth0 } from '@auth0/auth0-react';
import { type RootState } from 'common/store';
import PageHeader from 'features/ui/PageHeader/PageHeader';
import { UserRole } from 'lib/graphql/__generated__/graphql';
import { useSelector } from 'react-redux';

export default function EmailIsNotVerifiedPage() {
  const { user, logout } = useAuth0();

  const userMe = useSelector((state: RootState) => state.user.user);

  const handleClickTryAgain = () => {
    logout({
      logoutParams: {
        returnTo:
          userMe?.role === UserRole.Reviewer
            ? `${process.env.REACT_APP_LOGOUT_REDIRECT_URL}/reviewer`
            : `${process.env.REACT_APP_LOGOUT_REDIRECT_URL}`
      }
    });
  };
  return (
    <div>
      <PageHeader />
      <div className="grid place-content-center place-items-center h-[calc(100vh_-_72px)] w-full">
        <div className="grid grid-cols-1 place-content-center place-items-center w-full max-w-[464px]">
          <h1 className="text-[1.5rem] font-semibold leading-[132%] tracking-[-0.24px] text-[#0E1010]">
            Thank you for joining us
          </h1>
          <div className="grid grid-cols-1 place-content-center place-items-center gap-[0.5rem] w-full">
            <div className="grid grid-cols-1 place-content-center place-items-center w-full">
              <p className="text-[1rem] font-normal leading-[150%] tracking-[-0.16px] text-center text-[#7E8180]">
                Your activation email has been sent to
                <span className="text-[#4C35DE]">{user?.email ?? ''}</span>
              </p>

              <p className="text-[1rem] font-normal leading-[150%] tracking-[-0.16px] text-center text-[#7E8180]">
                Did you enter an incorrect email?
                <button className="text-[#0E1010] font-medium" onClick={handleClickTryAgain}>
                  Try again.
                </button>
              </p>
            </div>

            <p className="text-[1rem] font-normal leading-[150%] tracking-[-0.16px] text-center text-[#7E8180]">
              {
                'If you don’t find the email, check your spam folder. If the email didn’t arrive, contact our '
              }
              <a
                href="https://www.aaico.com/contact-us"
                target="_blank"
                rel="noreferrer"
                className="text-[#0E1010] font-medium">
                Support.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
