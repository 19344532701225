import { DeepDocJobWorkflowStatus } from 'lib/graphql/__generated__/graphql';

export const getCurrentStage = (workflowStatus: DeepDocJobWorkflowStatus) => {
  if (
    [
      DeepDocJobWorkflowStatus.OngoingCreateBoundary,
      DeepDocJobWorkflowStatus.QualityControlCreateBoundary
    ].includes(workflowStatus)
  ) {
    return 'boundary';
  }
  if (
    [
      DeepDocJobWorkflowStatus.OngoingDuplicateDetection,
      DeepDocJobWorkflowStatus.QualityControlDuplicateDetection,
      DeepDocJobWorkflowStatus.ProcessingDuplicateDetection
    ].includes(workflowStatus)
  ) {
    return 'duplicate';
  }
  if (
    [
      DeepDocJobWorkflowStatus.OngoingSummary,
      DeepDocJobWorkflowStatus.QualityControlSummary,
      DeepDocJobWorkflowStatus.ProcessingSummary
    ].includes(workflowStatus)
  ) {
    return 'summary';
  }
  if (
    [DeepDocJobWorkflowStatus.ProcessingOrganization, DeepDocJobWorkflowStatus.Done].includes(
      workflowStatus
    )
  ) {
    return 'done';
  }
  return;
};

export const getWorkflowsInStage = (stage: string): DeepDocJobWorkflowStatus[]|undefined => {
  if(stage === 'boundary') {
    return [
      DeepDocJobWorkflowStatus.OngoingCreateBoundary,
      DeepDocJobWorkflowStatus.QualityControlCreateBoundary
    ]
  } else if(stage === 'duplicate') {
    return [
      DeepDocJobWorkflowStatus.OngoingDuplicateDetection,
      DeepDocJobWorkflowStatus.QualityControlDuplicateDetection,
      DeepDocJobWorkflowStatus.ProcessingDuplicateDetection
    ]
  } else if(stage === 'summary') {
    return [
      DeepDocJobWorkflowStatus.OngoingSummary,
      DeepDocJobWorkflowStatus.QualityControlSummary,
      DeepDocJobWorkflowStatus.ProcessingSummary
    ]
  } else if(stage === 'done') {
    return [DeepDocJobWorkflowStatus.ProcessingOrganization, DeepDocJobWorkflowStatus.Done]
  }
  return 
}
