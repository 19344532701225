import { graphql } from '../__generated__/gql';

export const GET_DEEPDOC_JOB_PAGE_BOUNDARIES_BY_ID = graphql(`
  query getDeepDocJobPageBoundariesById($id: ID!) {
    job(id: $id) {
      id
      title
      serviceType
      createdAt
      ... on DeepDocJob {
        id
        workflowStatus
        pages {
          data {
            id
            rank
            presignedUrl
            section
          }
        }
        slices {
          id
          meta
          user {
            id
          }
        }
        boundaries {
          data {
            id
            rank
            type
            completed
            reviewed
            dateOfService
            dateOfServiceChanged
            dateOfServiceReviewed
            facility
            facilityChanged
            facilityReviewed
            physician
            physicianChanged
            physicianReviewed
            pages {
              id
              rank
              section
            }
          }
        }
        docTypes {
          data {
            color
            name
          }
        }
      }
    }
  }
`);

export const GET_SUMMARY_DEEPDOC_JOB_PAGE_BOUNDARIES_BY_ID = graphql(`
  query getSummaryDeepDocJobPageBoundariesById($id: ID!) {
    job(id: $id) {
      id
      title
      serviceType
      createdAt
      ... on DeepDocJob {
        id
        workflowStatus
        pages {
          data {
            id
            rank
            presignedUrl
            section
          }
        }
        boundaries {
          data {
            id
            rank
            type
            completed
            reviewed
            dateOfService
            dateOfServiceChanged
            dateOfServiceReviewed
            facility
            facilityChanged
            facilityReviewed
            physician
            physicianChanged
            physicianReviewed
            pages {
              id
              rank
              section
            }
          }
        }
        docTypes {
          data {
            color
            name
          }
        }
      }
    }
  }
`);

export const GET_DEEPDOC_JOB_TOC_BY_ID = graphql(`
  query getDeepDocJobTableofContentById($id: ID!) {
    job(id: $id) {
      id
      title
      serviceType
      createdAt
      ... on DeepDocJob {
        id
        taskType
        workflowStatus
        slaDatetime
        clientResult
      }
    }
  }
`);

export const GET_DEEPDOC_JOB_BOUNDARIES_BY_ID = graphql(`
  query getDeepDocJobBoundariesById($id: ID!) {
    job(id: $id) {
      ... on DeepDocJob {
        id
        boundaries {
          data {
            id
            rank
            type
            completed
            reviewed
            dateOfService
            dateOfServiceChanged
            dateOfServiceReviewed
            facility
            facilityChanged
            facilityReviewed
            physician
            physicianChanged
            physicianReviewed
            pages {
              id
              rank
              section
            }
          }
        }
      }
    }
  }
`);

export const GET_POTENTIAL_DUPLICATE_BOUNDARIES = graphql(`
  query getPotentialDuplicateBoundaries($id: ID!) {
    job(id: $id) {
      id
      title
      ... on DeepDocJob {
        duplicates {
          data {
            boundaryA
            boundaryB
            decision
            source
            id
          }
        }
      }
    }
  }
`);

export const GET_REFRESH_PAGE_PRESIGNED_URL = graphql(`
  query getRefreshPagePresignedUrl($deepDocJobId: String, $deepDocPageId: String) {
    page(deepDocJobId: $deepDocJobId, deepDocPageId: $deepDocPageId) {
      presignedUrl
    }
  }
`);
